import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isIbmBaaSEnabled, isMcmGovCloud } from '@cohesity/iris-core';

/**
 * Veritas info display date. Dec 10, 2024, 6am Pacific Time.
 */
const showVeritasInfoTime = new Date('2024-12-10T06:00:00-08:00').getTime();

/**
 * @description
 * Login page information service.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginInfoService {
  /**
   * True if showing Veritas info.
   */
  veritasEnabled = flagEnabled(this.ctx.irisContext, 'loginPageWithVeritasInfo') &&
    !isIbmBaaSEnabled(this.ctx.irisContext) &&
    !isMcmGovCloud(this.ctx.irisContext) &&
    (new Date().getTime() >= showVeritasInfoTime);

  constructor(
    private ctx: IrisContextService,
  ) {}
}
